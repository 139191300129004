import React, { useState } from "react";
import Input from "./Input";
import TextArea from "./TextArea";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";

const Form = (props) => {

  const handleChange = (e, setState) => {
    setState(e.target.value);
  };

  const [notification, setNotification] = useState("");
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");

  const { executeRecaptcha } = useGoogleReCaptcha();
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!executeRecaptcha) {
      return;
    }

    if (email === "") {
      setNotification(`Tu correo es requerido, a través de el te contactaremos inicialmente.`);
      return
    } else if (name === "") {
      setNotification(`¡Queremos saber el nombre de tu tienda!`);
      return
    }

    let result = await executeRecaptcha("homepage");

    let data = { email: email, name: name, extras: {token: result}, token: result }
    console.log(data)
    fetch("https://pentcloud-website-backend.vercel.app/public/v1/contacts", {
      method: "POST",
      headers: {
        "content-type": 'application/json'
      },
      body: JSON.stringify(data)
    })
    .then((res) => res.json())
    .then((d) => {
      setNotification('Gracias por contactarnos, en breve recibirás tus credenciales.'); //--> dynamically set your notification state via the server
      console.log(d)
    }, (e) =>{
      setNotification('Hubo un error, por favor verifica tus datos.'); //--> dynamically set your notification state via the server
      console.log(e)
    });
  };

  return (
    <form onSubmit={handleSubmit}>
        <div className="col-span-2 md:col-span-1 flex flex-col justify-center align-start mb-12">
          <Input id="formName" placeholder="Ingresa el nombre aquí" label="Nombre de tu tienda (*)" type="text" onChange={(e) => {handleChange(e, setName)}} value={name} required labelColor="text-white" />
          <span className="text-white text-sm mt-8 font-light">
            Usaremos este nombre para crear tu enlace único (tutienda.marketspace.digital)
          </span>
        </div>
        <div className="col-span-2 md:col-span-1 flex flex-col justify-center align-start">
          <Input id="formEmail" placeholder="Ingresa la dirección de correo aquí" label="Correo electrónico (*)" type="email" onChange={(e) => {handleChange(e, setEmail)}} value={email} required labelColor="text-white" />
        </div>
      <div className="flex flex-col justify-center items-center mt-12">
        <input type="submit" value="Empieza gratis ahora" className="px-8 py-4 bg-white text-purple rounded-lg w-60 mb-8" />
        {notification && (
          <span className="text-sm text-white">
            {notification}
          </span>
        )}
        <span className="text-white text-center text-sm my-8 font-light">
          Al registrarte estás de acuerdo con nuestros Términos y condiciones y nuestra Política de privacidad.
        </span>
        <span className="text-white text-center text-xs font-light">
          Este sitio está protegido por reCAPTCHA y se aplican la Política de privacidad y los Términos de servicio de Google.
        </span>
      </div>
    </form>
  );
};

export default Form;
