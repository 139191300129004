import React, { useState } from "react";
import Button from '../components/Button'
import Form from '../components/MarketSpaceForm'

let steps = [{
    icon: 'inbox',
    label: 'Bandeja de entrada'
},{
    icon: 'delivery-box',
    label: 'Preparación de paquete'
},{
    icon: 'truck',
    label: 'Envío'
},{
    icon: 'package',
    label: 'Recepción de paquete'
},{
    icon: 'rating',
    label: 'Calificación'
}]

let features = [
    'Productos ilimitados',
    'Categorías ilimitadas',
    'Sin límite de transacciones',
    'Costos de envío por zona',
    'Reportería y estadísticas',
    'Gestión de órdenes',
    'Tracking de órdenes',
    'Personaliza tu tienda',
    '1 Usuario Administrativo',
    'Enlace gratuito a tu tienda',
    'Certificado de seguridad',
    'Usuarios extra por $5'
]

let premiumFeatures = [
    'Gestión de inventario',
    'Agregar promociones',
    '3 usuarios administrativos',
    'Dominio personalizado',
    'Soporte personalizado',
    'Sistema de gestión de entregas',
    'Opción a integrar pasarela de pago'
]

let formRef = React.createRef()


const scrollTo = (ref) => {
    ref.current.scrollIntoView({behavior: 'smooth', block: 'center', inline: 'start'})
}

export default function MarketSpace(props) {
    const [plan, setPlan] = useState("monthly");
    const showPlan = (plan) => {
        setPlan(plan)
    }

    return (
        <div className="">
            <div className="container mx-auto grid grid-cols-5 items-center p-12">
                <div className="lg:col-span-2 col-span-5 flex flex-col">
                    <h1 className="text-4xl md:text-5xl font-bold text-gray mb-8 lg:leading-loose">
                        Empieza a <span className="text-purple">vender en línea</span> hoy mismo
                    </h1>
                    <span className="lg:text-gray-400 text-gray text-lg mb-8">
                        Dale a tus clientes una manera rápida y segura de hacer sus pedidos en línea con MarketSpace
                    </span>
                    <Button classes="px-8 py-4 bg-purple text-white rounded-lg mr-8 mb-8 w-72" onClick={() => scrollTo(formRef)}>
                        Prueba gratis ahora
                    </Button>
                    <span className="lg:text-gray-400 text-gray text-lg mb-8">
                        30 días gratis. No necesitas ingresar tarjeta de crédito.
                    </span>
                </div>
                <div className="hidden lg:col-span-3 lg:pr-12 lg:flex flex-row justify-start items-center transition ease-in-out duration-300">
                    <img src="https://cdn01.prd.pentcloud.com/site/landingmarketspace/1.png" alt="MarketSpace" className="transition ease-in-out duration-300" />
                </div>
            </div>
            <div className="p-12 bg-gray-50">
                <div className="container mx-auto flex flex-col items-center">
                    <h2 className="text-4xl md:text-5xl font-bold text-gray mb-8 md:leading-loose">
                        Estás a unos pasos de<br/>tu <span className="text-purple">tienda en línea</span>
                    </h2>
                    <div className="grid md:grid-cols-3 grid-cols-1 gap-12">
                        <div className="col-span-1 flex flex-col items-center justify-center p-12 text-center">
                            <img src="https://cdn01.prd.pentcloud.com/site/landingmarketspace/Icon1.svg" alt="MarketSpace" className="mb-8 transition ease-in-out duration-300" />
                            <span className="lg:text-gray-400 text-gray text-lg mb-8">
                                Gestiona fácilmente las órdenes de tus clientes
                            </span>
                        </div>
                        <div className="col-span-1 flex flex-col items-center justify-center p-12 text-center">
                            <img src="https://cdn01.prd.pentcloud.com/site/landingmarketspace/Icon2.svg" alt="MarketSpace" className="mb-8 transition ease-in-out duration-300" />
                            <span className="lg:text-gray-400 text-gray text-lg mb-8">
                                Configura tus costos de envío dependiendo de la zona
                            </span>
                        </div>
                        <div className="col-span-1 flex flex-col items-center justify-center p-12 text-center">
                            <img src="https://cdn01.prd.pentcloud.com/site/landingmarketspace/Icon3.svg" alt="MarketSpace" className="mb-8 transition ease-in-out duration-300" />
                            <span className="lg:text-gray-400 text-gray text-lg mb-8">
                                Obtén reportes de todo lo que pasa con tus productos
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container mx-auto grid grid-cols-2 items-center py-24 px-12">
                <div className="hidden lg:col-span-1 lg:pr-12 lg:flex flex-row justify-start items-center transition ease-in-out duration-300">
                    <img src="https://cdn01.prd.pentcloud.com/site/landingmarketspace/2.png" alt="MarketSpace" className="transition ease-in-out duration-300" />
                </div>
                <div className="lg:col-span-1 col-span-2 flex flex-col">
                    <h2 className="text-4xl md:text-5xl font-bold text-gray mb-8 md:leading-loose">
                        <span className="text-purple">Organiza</span> todo en un solo lugar
                    </h2>
                    <span className="lg:text-gray-400 text-gray text-lg mb-8">
                        Visualiza y controla el status de las órdenes desde que ingresan a tu bandeja de entrada hasta que las entregas a tus clientes.
                    </span>
                </div>
            </div>
            <div className="container mx-auto flex flex-col items-center text-center py-24">
                <h2 className="text-4xl md:text-5xl font-bold text-gray mb-8 md:leading-loose px-12">
                    Tu cliente visualizará el status de su orden a través de un enlace único
                </h2>
                <div className="flex flex-row flex-wrap justify-evenly items-center">
                    {
                        steps.map((step, i) => 
                            <div className="flex flex-col items-center justify-center p-12 text-center group transition ease-in-out duration-300" key={i}>
                                <div className="w-24 h-24 p-8 bg-purple-50 rounded-full mb-8 group-hover:bg-purple">
                                    <img src={`//pentcloud.com/images/${step.icon}.svg`} alt={step.label} className="white-filter" />
                                </div>
                                <div className={"h-1 w-16 bg-transparent group-hover:bg-purple transition duration-100 ease-in-out"}>
                                </div>
                            </div>
                        )
                    }
                </div>
            </div>
            <div className="container mx-auto grid grid-cols-2 items-center py-24 px-12">
                <div className="lg:col-span-1 col-span-2 flex flex-col">
                    <h2 className="text-4xl md:text-5xl font-bold text-gray mb-8 leading-loose">
                        <span className="text-purple">Personaliza</span> tu tienda
                    </h2>
                    <span className="lg:text-gray-400 text-gray text-lg mb-8">
                        Sube tu logo, elige los colores de tu preferencia, coloca información general de tu negocio, carga tus productos, conecta tus redes sociales.
                    </span>
                </div>
                <div className="hidden lg:col-span-1 lg:pr-12 lg:flex flex-row justify-start items-center transition ease-in-out duration-300">
                    <img src="https://cdn01.prd.pentcloud.com/site/landingmarketspace/3.png" alt="MarketSpace" className="transition ease-in-out duration-300" />
                </div>
            </div>
            <div className="px-12 bg-gray-50">
                <div className="container mx-auto grid grid-cols-2 items-center px-12">
                    <div className="hidden lg:col-span-1 lg:pr-12 lg:flex flex-row justify-start items-center transition ease-in-out duration-300">
                        <img src="https://cdn01.prd.pentcloud.com/site/landingmarketspace/4.png" alt="MarketSpace" className="transition ease-in-out duration-300" />
                    </div>
                    <div className="lg:col-span-1 col-span-2 lg:pt-0 pt-12 flex flex-col">
                        <span className="lg:text-gray-400 text-gray text-lg mb-8">
                            Tus clientes acceden a tu tienda en línea a tráves de un enlace público en donde podrán visualizar tu catálogo de productos y realizar sus órdenes en un carrito de compras.
                        </span>
                    </div>
                </div>
            </div>
            <div className="container mx-auto grid grid-cols-2 items-center py-24 px-12">
                <div className="lg:col-span-1 col-span-2 flex flex-col">
                    <h2 className="text-4xl md:text-5xl font-bold text-gray mb-8 md:leading-loose">
                        <span className="text-purple">Conoce</span> todo lo que pasa con tus productos
                    </h2>
                    <span className="lg:text-gray-400 text-gray text-lg mb-8">
                        Obtén estadísticas e información histórica de tus órdenes, consulta por rangos de fecha para comparar tus ventas y descarga dichos reportes en formato Excel o PDF.
                    </span>
                </div>
                <div className="hidden lg:col-span-1 lg:pr-12 lg:flex flex-row justify-start items-center transition ease-in-out duration-300">
                    <img src="https://cdn01.prd.pentcloud.com/site/landingmarketspace/5.png" alt="MarketSpace" className="transition ease-in-out duration-300" />
                </div>
            </div>
            <div className="container mx-auto grid grid-cols-2 items-center py-24 px-12">
                <div className="hidden lg:col-span-1 lg:pr-12 lg:flex flex-row justify-start items-center transition ease-in-out duration-300">
                    <img src="https://cdn01.prd.pentcloud.com/site/landingmarketspace/6.png" alt="MarketSpace" className="transition ease-in-out duration-300" />
                </div>
                <div className="lg:col-span-1 col-span-2 flex flex-col">
                    <h2 className="text-4xl md:text-5xl font-bold text-gray mb-8 md:leading-loose">
                        <span className="text-purple">Crea promociones</span> que venden
                    </h2>
                    <span className="lg:text-gray-400 text-gray text-lg mb-8">
                        Crea promociones sobre uno o varios productos y establece tus condiciones para que tus clientes se beneficien con los descuentos de tu tienda.
                    </span>
                </div>
            </div>
            <div className="container mx-auto lg:w-1/2 w-3/4 flex items-center justify-center py-24 px-12">
                <Button classes={`px-8 py-4 rounded-lg mr-8 ${plan === 'monthly' ? 'bg-purple text-white ' : 'bg-white text-gray border-2 border-gray-200'}`} onClick={() => showPlan('monthly')}>
                    Pago mensual
                </Button>
                <Button classes={`px-8 py-4 rounded-lg ${plan === 'yearly' ? 'bg-purple text-white ' : 'bg-white text-gray border-2 border-gray-200'}`} onClick={() => showPlan('yearly')} >
                    Pago anual
                </Button>
            </div>
            <div className="container mx-auto flex flex-col items-center justify-center px-12">
                <h2 className="text-4xl md:text-5xl font-bold text-gray mb-8 md:leading-loose">
                    <span className="text-purple">Configura tu tienda ahora</span>, elige tu plan después
                </h2>
                <span className="lg:text-gray-400 text-gray text-lg mb-8">
                    Explora las opciones. Recuerda que <span className="text-purple">tienes 30 días gratis</span> y no necesitas tarjeta de crédito para empezar.
                </span>
            </div>
            <div className="container mx-auto flex flex-col items-center justify-center px-12">
                <div className="lg:w-3/4 w-full grid md:grid-cols-2 grid-cols-1 justify-center py-24">
                    <div className="rounded-xl bg-gray-50 p-12 grid-col-1 flex flex-col place-content-start md:mr-6">
                        <h3 className="font-light text-gray text-2xl">Plan básico</h3>
                        <span className="text-gray font-bold text-3xl mb-8">Q{plan === 'monthly' ? '115' : '1150'}/<span className="text-gray font-bold text-base">{plan === 'monthly' ? 'mes' : 'anual'}</span></span>
                        <span className="text-gray text-lg mb-8">
                            Tienda en línea fácil para lanzar tu negocio hoy mismo
                        </span>
                        <div>
                            {
                                features.map((feature, i) =>
                                    <div className="flex flex-row mb-4 last:mb-12 items-center" key={i}>
                                        <div className="rounded-full flex justify-center items-center bg-purple mr-4 w-8 h-8">
                                            <img src="//pentcloud.com/images/CheckWhite.svg" alt="MarketSpace" className="transition ease-in-out duration-300" />
                                        </div>
                                        <span className="text-gray text-lg">
                                            {feature}
                                        </span>
                                    </div>
                                )
                            }
                        </div>
                        <Button classes="px-8 py-4 bg-white border-2 border-gray-200 text-gray rounded-lg" onClick={() => scrollTo(formRef)} >
                            Empieza gratis
                        </Button>
                    </div>
                    <div className="rounded-xl bg-purple-300 p-12 grid-col-1 flex flex-col place-content-start md:ml-6">
                        <h3 className="font-light text-white text-2xl">Plan avanzado</h3>
                        <span className="text-white font-bold text-3xl mb-8">Q{plan === 'monthly' ? '193' : '1930'}/<span className="text-white font-bold text-base">{plan === 'monthly' ? 'mes' : 'anual'}</span></span>
                        <span className="text-white text-lg mb-8">
                            Funciones avanzadas para crecer tu tienda en línea
                        </span>
                        <div>
                        {
                                features.map((feature, i) =>
                                    <div className="flex flex-row mb-4 last:mb-12 items-center" key={i}>
                                        <div className="rounded-full flex justify-center items-center bg-white mr-4 w-8 h-8">
                                            <img src="//pentcloud.com/images/CheckPurple.svg" alt="MarketSpace" className="transition ease-in-out duration-300" />
                                        </div>
                                        <span className="text-white text-lg">
                                            {feature}
                                        </span>
                                    </div>
                                )
                            }
                            {
                                premiumFeatures.map((feature, i) =>
                                    <div className="flex flex-row mb-4 last:mb-12 items-center" key={i}>
                                        <div className="rounded-full flex justify-center items-center bg-white mr-4 w-8 h-8">
                                            <img src="//pentcloud.com/images/CheckPurple.svg" alt="MarketSpace" className="transition ease-in-out duration-300" />
                                        </div>
                                        <span className="text-white text-lg">
                                            {feature}
                                        </span>
                                    </div>
                                )
                            }
                        </div>
                        <Button classes="px-8 py-4 bg-white border-2 border-gray-200 text-gray rounded-lg" onClick={() => scrollTo(formRef)} >
                            Empieza gratis
                        </Button>
                    </div>
                </div>
            </div>
            <div className="px-12 bg-purple py-24">
                <div className="container mx-auto grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 items-center lg:px-12">
                    <div className="lg:col-span-2 col-span-1 lg:pr-12 flex flex-col justify-center items-center">
                        <h2 className="text-4xl md:text-5xl font-bold text-white mb-8 md:leading-loose">
                        Regístrate y empieza
                        <br/> a vender en línea HOY
                        </h2>
                        <span className="text-white sm:text-center text-lg mb-8">
                            ¿Tienes dudas? escríbenos a info@pentcloud.com
                        </span>
                        <span className="text-white text-lg mb-8 font-bold">
                            Contáctanos al número <br/> (+502) 2214-3232 Ext. 9012
                        </span>
                    </div>
                    <div className="col-span-1 flex flex-col" ref={formRef}>
                        <Form />
                    </div>
                </div>
            </div>
        </div>
    )
}