import React from 'react'
const TextArea = (props) =>
<div>
	{props.id ?(
		<div>
			<label htmlFor={props.id} className={`text-base ${props.labelColor || 'text-gray'}`} >{props.label}</label>
			<textarea  id={props.id} className="w-full px-8 py-4 mt-4 focus:outline-none focus:ring-2 focus:ring-green-900 bg-gray-100 rounded-md" type={props.type} placeholder={props.placeholder} disabled= {props.disabled || false} rows={props.rows || 4} onChange={event => props.onChange(event)} />
		</div>
	) : (
			<span className="text-sm text-gray-400">Attributo Id es necesario</span>
	)
	}
</div>

export default TextArea